import React from "react";
import { Link } from "react-router-dom";
import "../styles/Introduction.scss";

const Introduction = () => {
  return (
    <div>
      <div className="intro-container">
        <div className="image-container">
          <img
            src="/images/China_Educational_Holiday.webp"
            alt="China Educational Holiday"
          />
          <div className="overlay">
            <Link to="/register">
              <button className="overlay-button">
                Join us for an unforgettable educational journey
              </button>
            </Link>
          </div>
        </div>
        <h1>Welcome to Our Community-Driven Holiday Camp Adventure!</h1>
        <p>
          We organize educational trips to China, designed to spark curiosity in
          young, inquisitive minds. Join us for an unforgettable journey where
          adventure meets learning, exploring ancient history, vibrant cities,
          and cultural wonders. Whether you're a curious explorer or an eager
          learner, this trip offers an immersive experience for everyone!
        </p>
        <div className="card">
          <p>
            <strong>
              Camp: 欧洲华裔青少年寻根 北京西安行 Beijing-Xian Summer Camp
            </strong>
            <br />
            <strong>Days: 12</strong>
            <br />
            <strong>Date: 12/07/2025 - 23/07/2025</strong>
          </p>
          <p>
            欧洲华裔青少年寻根之旅旨在带领13到22岁的青少年穿越千年历史的北京与西安，了解中国文化的精髓，并以创新科技为载体，激发他们的探索精神和未来潜力。在为期12天的活动中，营员们将深入历史名胜、接触前沿科技，与同龄人共同挑战自我，收获难忘的回忆与宝贵的成长经验。
          </p>
          <p>
            This summer camp is crafted for young people aged 13 to 22,
            providing an immersive journey through the millennia-old cultural
            landscapes of Beijing and Xi’an. It combines ancient Chinese
            heritage with modern technological exposure, aiming to inspire
            curiosity, critical thinking, and future potential. Over the course
            of 12 days, participants will dive into historic sites, engage with
            cutting-edge tech, and embark on self-discovery with like-minded
            peers, making it a memorable and transformative experience.
          </p>
        </div>
        <Link to="/register">
          <button className="register-button">Register Camp Details</button>
        </Link>
      </div>
    </div>
  );
};

export default Introduction;
