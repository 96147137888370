const env = process.env.NODE_ENV || "dev";
let config;

if (env === "production") {
  config = require("./config.prod").default;
} else {
  config = require("./config.dev").default;
}

export default config;
